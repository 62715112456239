import React, { useState, useEffect } from "react";
import { Container, Row, Button, ButtonGroup } from "react-bootstrap";
import Particle from "../Particle";
import pdfEnglish from "../../Assets/../Assets/Gedion-Daniel-CV-V1.pdf";
import pdfItalian from "../../Assets/../Assets/Gedion-Daniel-Resume-Italian.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import './ResumeNew.css'; // Ensure the import statement is at the top

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [width, setWidth] = useState(1200);
  const [selectedLanguage, setSelectedLanguage] = useState('english');
  const [pdf, setPdf] = useState(pdfEnglish);

  useEffect(() => {
    setWidth(window.innerWidth);

    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setPdf(selectedLanguage === 'english' ? pdfEnglish : pdfItalian);
  }, [selectedLanguage]);

  // Adjust the scale for different window sizes to decrease the display size
  const scale = width > 786 ? 1.2 : 0.5;

  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />
        {/* <Row style={{ justifyContent: "center", position: "relative" }}>
          <ButtonGroup className="language-button-group">
            <Button
              variant={selectedLanguage === 'english' ? "primary" : "outline-primary"}
              onClick={() => setSelectedLanguage('english')}
              className="language-button"
            >
              English
            </Button>
            <Button
              variant={selectedLanguage === 'italian' ? "primary" : "outline-primary"}
              onClick={() => setSelectedLanguage('italian')}
              className="language-button"
            >
              Italian
            </Button>
          </ButtonGroup>
        </Row> F*/}

        <Row className="resume">
          <Document file={pdf} className="d-flex justify-content-center">
            <Page pageNumber={1} scale={scale} />
          </Document>
        </Row>

        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button
            variant="primary"
            href={pdf}
            target="_blank"
            style={{ maxWidth: "250px" }}
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </div>
  );
}

export default ResumeNew;
