import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/profile-picture.jpg";
import awsqst from "../../Assets/cloud-quest.png";
import awsess from "../../Assets/cloud-essentials.png";
import awsops from "../../Assets/cloud-ops.png";
import awscp from "../../Assets/cloud-practitioner.png";
import awssls from "../../Assets/cloud-serverless.png";
// import awsdev from "../../Assets/aws-developer-associate.png";
// import awssol from "../../Assets/aws-solution-architect.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  const certifications = [
    {
      title: "AWS Cloud Quest: Cloud Practitioner",
      image: awscp,
      link: "https://www.credly.com/badges/1f60e5db-f4b1-43bf-bdc9-c49118c2b670/public_url"
    },
    {
      title: "AWS Cloud Quest: Cloud Practitioner",
      image: awsqst,
      link: "https://www.credly.com/badges/ae4a93c9-aa9a-420b-a286-ada076069228/public_url"
    },
    {
      title: "AWS Knowledge: Cloud Essentials",
      image: awsess,
      link: "https://www.credly.com/badges/b7862d8e-eddf-400f-8209-078d19ea1fb7/public_url"
    },
    {
      title: "AWS Educate Getting Started with Cloud Ops",
      image: awsops,
      link: "https://www.credly.com/badges/cb753842-1e84-4201-962b-abe770c45ab9/public_url"
    },
    {
      title: "AWS Educate Getting Started with Serverless",
      image: awssls,
      link: "https://www.credly.com/badges/467748b3-134c-42a7-a78f-294d03b5d26e/public_url"
    }
  ];

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        {/* Existing intro section */}
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Hey, <span className="purple"> I'm Gedion </span> 👋🏻
            </h1>
            <p className="home-about-body">
              I'm a problem solver who enjoys learning and sharing knowledge. Based in Italy 🇮🇹
              <br />
              <br />
              My fields of interest include building new &nbsp;
              <i>
                <b className="purple">Web Technologies and Products </b> as well as cloud computing with {" "}
                <b className="purple">
                  AWS.
                </b>
              </i>
              <br />
              <br />
              I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js and Next.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img
                src={myImg}
                className="img-fluid"
                alt="avatar"
                style={{
                  borderRadius: '50%',
                  width: '240px',
                  height: '240px',
                  objectFit: 'cover',
                  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)'
                }}
              />
            </Tilt>
          </Col>
        </Row>

        {/* Certifications Section */}
        <Row className="certification-section">
          <Col md={12}>
            <h1 style={{ fontSize: "2.6em", textAlign: "center", marginBottom: "1em" }}>
            <span className="purple">Certifications</span>
            </h1>
          </Col>
          <Row style={{ justifyContent: "center" }}>
            {certifications.map((cert, index) => (
              <Col md={4} className="certification-card" key={index}>
                <a href={cert.link} target="_blank" rel="noreferrer" className="certification-link">
                  <img
                    src={cert.image}
                    alt={cert.title}
                    className="certification-image"
                  />
                </a>
              </Col>
            ))}
          </Row>
        </Row>

        {/* Social Links Section */}
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Gediontkd"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/gedion-daniel-760ba6280/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/gediondev/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;